<template>
  <div id="authentication">
    <div class="authentication__container">
      <section
        class="hero is-primary is-fullheight"
        id="auth-page"
        style="background-image: url('/images/auth-bg-2.jpg')"
      >
        <div class="hero-body">
          <div class="container">
            <div class="auth__header">
              <div class="auth__logo is-flex is-justify-content-center">
                <img
                  src="/images/rise_logo_red.png"
                  alt="RISE"
                  class="rise-auth-logo"
                />
              </div>
            </div>
            <r-card class="auth__form login-redirect-card">
              <router-view />
            </r-card>
            <div class="has-text-centered mt-5">
              by Roketin v. {{ version }}
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import { version } from '../constant'

export default {
  data() {
    return {
      version: version,
    }
  },
}
</script>
